<template>
  <div
    id="main"
    class="text-center align-center"
  >
    <p class="border-bottom-2px-silver">
      Radni nalog: {{ $route.params.reference }} - {{ $route.params.code }}
    </p>
    <p class="border-bottom-2px-silver">
      {{ $route.params.company }} {{ $route.params.contactPerson }} {{ $route.params.phone }}
    </p>
    <p
      class="mb-0"
    >
      <span>{{ $route.params.reference }} {{ total() }}</span><br />
      <img
        :id="`barcode1`"
        alt="SistemCD"
      />
    </p>
    <p class="border-bottom-2px-silver">
      Polica: {{ $route.params.shelf }}
    </p>
    <p class="border-bottom-2px-silver">
      SistemCD Beograd | https://sistemcd.rs | 011/2457-666
    </p>
    <div id="toolbar">
      <v-btn
        class="mb-3"
        block
        small
        outlined
        @click="print()"
      >
        {{ $t('Print') }}
      </v-btn>
      <v-btn
        class="mb-3"
        block
        small
        outlined
        @click="back()"
      >
        {{ $t('Close') }}
      </v-btn>
    </div>
  </div>
</template>

<script>

import JsBarcode from 'jsbarcode'

export default {
  name: 'WarrantBarcode',
  data() {
    return {}
  },
  mounted() {
    this.generateBarcode(this.$route.params.reference, this.total().replaceAll(",", ""))
  },
  methods: {
    total() {
      return (+this.$route.params.products.reduce((acc, curr) => acc + +((curr.price.replaceAll(",", "") * 1.2).toFixed(2)) * curr.quantity, 0)).toLocaleString('en-GB', { maximumFractionDigits: 2 })
    },
    generateBarcode(code, price) {
      JsBarcode(`#barcode1`, `${code} ${price}`, {
        width: 1.45,
        height: 40,
        displayValue: false,
      })
    },
    print() {
      window.print()
    },
    back() {
      window.history.back()
    },
  },

}
</script>
<style scoped>
  #main p {
    width: 100% !important;
    background: white;
  }
  #main {
    width: 310px;
    max-width: 310px;
    margin: 0 auto;
    font-size: 80%;
    text-align: center;
  }
  .border-bottom-2px-silver {
    padding: 0.1em !important;
    margin: 0.1em !important;
  }
  @media print {
    #toolbar {
      display: none;
    }
  }
</style>
